<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <CRow>
                <CCol lg="5">
                    <CCard>
                        <CCardHeader><font-awesome-icon icon="list-ol"/>  
                            New Module
                            <font-awesome-icon style="color: #4caf50;" icon="info-circle" size="lg"/>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol lg="10">
                                    <label>Please select group</label>
                                    <v-select 
                                        label="name"
                                        v-model="module.group"
                                        :options="group_lists"
                                        :class="!module.group ? 'has-error' : 'has-success'"
                                    />
                                </CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <CCol lg="10">
                                   Module Type &nbsp;&nbsp; 
                                   <a-popover title="NOTE:" placement="left">
                                        <template slot="content">
                                            <ModuleTypeHelp/>
                                        </template>
                                        <font-awesome-icon 
                                            style="color: #468dc8;" 
                                            icon="question-circle" 
                                        />
                                    </a-popover>
                                </CCol>
                                <CCol lg="10">
                                    <p-radio class="p-icon p-round p-jelly" name="type" color="warning" value="basic" checked="checked" v-model="module.type">
                                        Basic

                                    </p-radio>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    <p-radio class="p-icon p-round p-jelly" name="type" value="advanced" color="danger"  v-model="module.type">
                                        Advanced &nbsp; 
                                    </p-radio>
                                </CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <CCol lg="10">
                                    <CInput 
                                        :lazy="false"
                                        :value.sync="$v.module.name.$model"
                                        :isValid="checkIfValid('name')"
                                        label="Name" 
                                        type="text" 
                                        placeholder="User" 
                                        autocomplete="off"  
                                        v-nospecialcharacter
                                        v-tocapitalize
                                        v-model="module.name"
                                    >
                                    <template #append-content>
                                       <a-popover title="NOTE!">
                                            <template slot="content">
                                            <p>Use singular word.</p>
                                            </template>
                                                <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                        </a-popover>
                                    </template>
                                    </CInput>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="10">
                                    <CInput 
                                        :lazy="false"
                                        :value.sync="$v.module.url.$model"
                                        :isValid="checkIfValid('url')"
                                        label="Url" 
                                        type="text" 
                                        placeholder="user" 
                                        autocomplete="off"  
                                        v-model="module.url"
                                        readonly
                                    >
                                    <template #prepend-content>
                                        /
                                    </template>
                                    
                                    </CInput>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="10">
                                    <CInput 
                                        :lazy="false"
                                        :value.sync="$v.module.icon.$model"
                                        :isValid="checkIfValid('icon')"
                                        label="Select Icon" 
                                        type="text" 
                                        placeholder="Select icon" 
                                        autocomplete="off" 
                                        readonly 
                                        v-model="module.icon"
                                    >
                                        <template #prepend-content>
                                            <CIcon :name="module.icon"/>
                                        </template>
                                        
                                        <template #append>
                                            <CButton color="primary"  @click="$refs.iconModal.modal = true, emitIconModal()">
                                            <font-awesome-icon icon="search"/>
                                            </CButton>
                                        </template>
                                    </CInput>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <CTextarea
                                        label="Description"
                                        placeholder="Content..."
                                        autocomplete="off"  
                                        rows="3"
                                        v-model="module.description"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol col="12" class="text-right">
                                    <CButton 
                                        :disabled="!isValid"
                                        type="button" 
                                        shape="pill" 
                                        color="primary"
                                        @click="saveModule()"
                                    >
                                        <font-awesome-icon icon="save"/> Save
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol lg="7">
                    <CCard>
                        <CCardHeader>
                            <font-awesome-icon icon="list-ol"/>  
                            Module Access 
                            <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                            <CButton
                                class="float-right"
                                type="button" 
                                color="primary"
                                size="sm"
                                @click="addDefaultModuleAccess()"
                                v-if="module.name && module.url"
                            >
                                <font-awesome-icon icon="plus-circle"/> Add Default
                            </CButton>
                        </CCardHeader>
                        <CCardBody>
                            
                            <CRow v-if="module.name && module.url">
                                <CCol lg="3">Name</CCol>
                                <CCol lg="4">Slug</CCol>
                                <CCol lg="4">URL</CCol>
                                <CCol lg="1"></CCol>
                            </CRow>
                            <CRow v-if="module.name && module.url">
                                <CCol lg="3">
                                        <CInput 
                                            type="text" 
                                            placeholder="Create" 
                                            autocomplete="off"  
                                            v-model="custom_module_access.name"
                                        />
                                </CCol>
                                <CCol lg="4">
                                        <CInput 
                                            type="text" 
                                            placeholder="create" 
                                            autocomplete="off"  
                                            v-model="custom_module_access.slug"
                                        />
                                </CCol>
                                <CCol lg="4">
                                        <CInput 
                                            type="text" 
                                            placeholder="create" 
                                            autocomplete="off"  
                                            v-model="custom_module_access.url"
                                        >
                                            <template #prepend-content>
                                            /
                                        </template>
                                        </CInput>
                                </CCol>
                                <CCol lg="1">
                                        <CButton 
                                            class="float-right"
                                            type="button" 
                                            color="primary"
                                            @click="addCustomModuleAccess()"
                                        >
                                            <font-awesome-icon icon="plus-square"/> 
                                        </CButton>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <CDataTable
                                        :items="module.access_lists"
                                        :fields="access_lists_fields"
                                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                        :items-per-page="10"
                                        border
                                        :tableFilter="false"
                                        sorter
                                        pagination
                                    >
                                        <template #url="{item}">
                                            <td>
                                                {{item.url ? `/${module.url}${item.url}` : ''}}
                                            </td>
                                        </template>
                                        <template #type="{item}">
                                            <td>
                                                {{item.url ? 'Component' : 'API'}}
                                            </td>
                                        </template>
                                        <template #action="{item}">
                                            <td style="width: 10%;">
                                                <center>
                                                    <CButton 
                                                        color="danger" 
                                                        @click="removeAccess(item)" 
                                                        shape="pill" 
                                                        size="sm"
                                                    >
                                                        <font-awesome-icon icon="times"/>
                                                    </CButton>
                                                </center>
                                            </td>
                                        </template>
                                    </CDataTable>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <IconsModal ref="iconModal"/>
        </div>
    </div>
</template>
<script>
import IconsModal from '../../modals/IconsModal'
import ModuleTypeHelp from './components/ModuleTypeHelp.vue';
import { required,minLength } from "vuelidate/lib/validators"
export default {
    name: 'ModuleCreate',
    components: {
        IconsModal,
        ModuleTypeHelp,
    },
    data () {
        return {
            group_lists: [],
            module: {
                icon: 'cil-folder',
                group:null,
                name: null,
                url: null,
                description: null,
                type:null,
                access_lists: [],
            },
            access_lists_fields:[
                { key: 'name', sorter: false},
                { key: 'slug', sorter: false},
                { key: 'url',  sorter: false},
                { key: 'type',  sorter: false},
                { key: 'action',  sorter: false},
            ],
            custom_module_access: {
                name: '',
                slug: '',
                url: '',
            }
        }
    },
    computed: {
        isValid () { return !this.$v.module.$invalid },
        isDirty () { return this.$v.module.$anyDirty },
    },
    created() {
        this.validate();
        this.getGroups();
        this.$emit('activeTab', 1);
    },
    validations: {
        module: {
            group: { required, },
            icon: { required, },
            name: { required, },
            url: { required, },
            type: { required, },
            access_lists: { required, minLength: minLength(1) },
        }
    },
    mounted() {
        this.$watch(
            "$refs.iconModal.selected",
            (new_value, old_value) => (this.module.icon = new_value)
        )
    },
    methods: {
        getGroups: function() {
            axios.get('/groups/active-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            name: response.data.data[i].name
                        }
                        this.group_lists.push(option)
                    }
                }
            })
        },
        validate: function () {
            this.$v.$touch()
        },
        validateCustomAccess: function() {
            if(this.custom_module_access.name == '' || this.custom_module_access.name == null &&
                this.custom_module_access.slug == '' || this.custom_module_access.slug == null
                ) return false;
            return true;
        },
        validateModule:function () {
            if(!this.module.name ||
                !this.module.url ||
                this.module.access_lists.length <= 0) {
                    return false;
            } 
            return true;
        },
        emitIconModal: function() {
            this.$emit('show_icons_modal');
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.module[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        removeAccess: function(item) {
            for(let i = 0; i < this.module.access_lists.length; i++) {
                if(this.module.access_lists[i].name == item.name &&
                    this.module.access_lists[i].url == item.url &&
                    this.module.access_lists[i].slug == item.slug
                ) {
                    this.module.access_lists.splice(i, 1)
                    break;
                }
            }
        },
        addCustomModuleAccess: function() {
            if(!this.validateCustomAccess()) {
                return this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'error',
                        title: 'Name and Slug cannot be empty.',
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
            }
            let url = this.custom_module_access.url ?
                     `/${this.custom_module_access.url.replace(/\\|\//g,'')}` :
                     null;

            if(!this.checkIfAccessExists(url, this.custom_module_access.slug, 'custom')) {
                let custom_access = {
                    name: this.custom_module_access.name,
                    slug: this.custom_module_access.slug,
                    url: url,
                }
                this.module.access_lists.push(custom_access)
                this.custom_module_access = this.clearCustomAccess();
            }

        },
        clearCustomAccess: function() {
           return {
               name: '',
               slug: '',
               url: '',
           }
        },
        addDefaultModuleAccess: function() {
            
            let default_access_0 = {name: `${this.module.name} List`, slug: `${this.module.url.toLowerCase()}-list`, url: `/`}
            if(!this.checkIfAccessExists(default_access_0.url, default_access_0.slug, 'bulk')) {
                this.module.access_lists.push(default_access_0)
            }

            let default_access_1 = {name: `Create ${this.module.name}`, slug: `create-${this.module.url.toLowerCase()}`, url: `/create`}
            if(!this.checkIfAccessExists(default_access_1.url, default_access_1.slug, 'bulk')) {
                this.module.access_lists.push(default_access_1)
            }

            let default_access_2 = {name: `View ${this.module.name}`, slug: `view-${this.module.url.toLowerCase()}`, url: `/:id`}
            if(!this.checkIfAccessExists(default_access_2.url, default_access_2.slug, 'bulk')) {
                this.module.access_lists.push(default_access_2)
            }

            let default_access_3 = {name: `Edit ${this.module.name}`, slug: `edit-${this.module.url.toLowerCase()}`, url: null}
            if(!this.checkIfAccessExists(default_access_3.url, default_access_3.slug, 'bulk')) {
                this.module.access_lists.push(default_access_3)
            }

            let default_access_4 = {name: `Update ${this.module.name}`, slug: `update-${this.module.url.toLowerCase()}`, url: null}
            if(!this.checkIfAccessExists(default_access_4.url, default_access_4.slug, 'bulk')) {
                this.module.access_lists.push(default_access_4)
            }

            let default_access_5 = {name: `Archive ${this.module.name}`, slug: `archive-${this.module.url.toLowerCase()}`, url: null}
            if(!this.checkIfAccessExists(default_access_5.url, default_access_5.slug, 'bulk')) {
                this.module.access_lists.push(default_access_5)
            }

            let default_access_6 = {name: `Restore ${this.module.name}`, slug: `restore-${this.module.url.toLowerCase()}`, url: null}
            if(!this.checkIfAccessExists(default_access_6.url, default_access_6.slug, 'bulk')) {
                this.module.access_lists.push(default_access_6)
            }
        },
        checkIfAccessExists: function(url, slug, type) {
            for(let i = 0; i < this.module.access_lists.length; i++) {
                if( this.module.access_lists[i].url == url &&
                    this.module.access_lists[i].slug == slug
                ) {
                    if(type == 'custom')
                    {
                        return this.$swal({
                            toast: true,
                            position: 'top-right',
                            timer: 3000,
                            icon: 'error',
                            title: 'Access already exists in the list.',
                            showConfirmButton: false,
                            timerProgressBar: true,
                        }) 
                    } else {
                        return true;
                    }
                }
            }
        },
        saveModule: function() {
            if(!this.validateModule()) {
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
                return;
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to add new module.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/modules/store', this.module, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `${this.module.name} has been added.`,
                            })
                            this.module = this.clearForm();
                            this.$v.$reset()
                            this.$v.$touch()
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        clearForm: function() {
            return {
                icon: 'cil-folder',
                name: null,
                url: null,
                description: null,
                type: null,
                access_lists: [],
            }
        }
    },
    watch: {
        'module.name': function(value) {
            this.module.url = value ? value.replace(/\s+/g, '-').toLowerCase() : ''
            if(!value) {
                this.module.access_lists = [];
            }
        }
    }
}
</script>